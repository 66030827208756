//==============================
// Header
//==============================


var header = $('.header');
var button = $('.header-button');
var nav = $('.header-nav');

// button
$('.header-button').click(function() {
  button.toggleClass('open');
  nav.toggleClass('open');
});



// hide header on scroll
// http://www.jquerybyexample.net/2013/07/jquery-detect-scroll-position-up-down.html
var scrollPos = 0;
$(window).scroll(function () {
  var currentScrollPos = $(this).scrollTop();
  if (currentScrollPos > scrollPos && currentScrollPos > 150 && header.is(':hover') == false) {
    header.addClass('hidden');
  } else {
    header.removeClass('hidden');
  }
  scrollPos = currentScrollPos;
});
